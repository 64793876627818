// JS libraries
import "../scripts/frontend/setup_jquery";
import "../scripts/frontend/setup_translations";

import Rails from 'rails-ujs';
Rails.start();

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start();

import CategoryDropdownController from '../scripts/controllers/category_dropdown_controller';
import CheckboxController from '../scripts/controllers/checkbox_controller';
import FilterController from '../scripts/controllers/filter_controller';
import LightboxController from '../scripts/controllers/lightbox_controller';
import ToggleContentController from '../scripts/controllers/toggle_content_controller';
import 'bootstrap';

import { Fancybox } from "@fancyapps/ui";

application.register("category-dropdown", CategoryDropdownController);
application.register("checkbox", CheckboxController);
application.register("filter", FilterController);
application.register("lightbox", LightboxController);
application.register("toggle-content", ToggleContentController);

jQuery(function() {
  Fancybox.bind("a[data-lightbox='audio-clips']");
});
