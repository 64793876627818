/* global window */
/* global document */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "latitudeInput",
    "longitudeInput",
    "placesAutocompleteInput",
    "playingInInput",
  ]

  connect() {
    window.filterGoogleCallback = this.filterGoogleCallback.bind(this);
  }

  addComponents (searchParams) {
    var addressComponents = searchParams.addressComponents;

    for (var i = 0; i < addressComponents.length; i++) {
      var types = addressComponents[i].types
      var component = addressComponents[i]

      if (types.indexOf("postal_code") !== -1) {
        searchParams["postalCode"] = component.short_name;
      }

      if (!searchParams["locality"] &&
          (types.indexOf("sublocality") !== -1 || types.indexOf("locality") !== -1)) {
        searchParams["locality"] = component.long_name;
      }
    }

    return searchParams;
  }

  triggerDropdown () {
    this.placesAutocompleteInputTarget.blur();
    this.placesAutocompleteInputTarget.focus();
  }

  dontSubmit (input) {
    // dont submit the form when pressing enter in the autocomplete field
    google.maps.event.addDomListener(input, "keydown", function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });
  }

  filterGoogleCallback () {
    window.googleMapsIsLoaded = true;

    this.setupFilter();

    if (this.isInputElementFocused()) {
      setTimeout(this.triggerDropdown.bind(this), 100);
    }
  }

  geoPosition (placeLocation) {
    var geoPosition = [placeLocation.lat(), placeLocation.lng()]

    return geoPosition;
  }

  getFirstAutocompleteResult (input, eventTarget, callback) {
    var options = this.autocompleteOptions(eventTarget);
    options['input'] = input;
    var autocompleter = new google.maps.places.AutocompleteService();
    autocompleter.getPlacePredictions(
      options,
      function(results) {
        if (results) {
          callback(results[0]);
        }
      }
    )
  }

  getPlaceFromPrediction (autocompletePrediction, callback) {
    var attributionsContainer = document.createElement("div");
    var placesService = new google.maps.places.PlacesService(attributionsContainer);
    var place = placesService.getDetails(
      {
        placeId: autocompletePrediction.place_id,
        fields: [
          "address_components",
          "geometry"
        ]
      },
      callback
    )
  }

  getSearchParamsFromPlace (place) {
    if (place) {
      var searchParams = {}

      searchParams["addressComponents"] = place.address_components;
      searchParams["placeLocation"] = place.geometry.location;
      searchParams["geoPosition"] = this.geoPosition(searchParams.placeLocation);
      searchParams = this.addComponents(searchParams);
      searchParams["placeDescription"] = this.placeDescription(searchParams);

      return searchParams
    } else {
      return {};
    };
  }

  isInputElementFocused () {
    document.activeElement === this.placesAutocompleteInputTarget
  }

  placeDescription (searchParams) {
    var placeDescription = ""

    if (searchParams.postalCode) {
      placeDescription += (searchParams.postalCode);
    }

    if (searchParams.locality) {
      placeDescription += (" " + searchParams.locality);
    }

    // if we dont have any values, use the users input
    if (placeDescription == "") {
      placeDescription = document.getElementById("artist_location").value;
    }

    placeDescription = $.trim(placeDescription);

    return placeDescription;
  }

  resetFormFields() {
    this.latitudeInputTarget.value = "";
    this.longitudeInputTarget.value = "";
    if (this.hasPlayingInInputTarget) { this.playingInInputTarget.value = ""; }
  }

  setupFilter() {
    if (!window.googleMapsIsLoaded) {
      this.loadAutoComplete();
      setTimeout(this.setupFilter.bind(this), 100);
    } else {
      if (!this.autocomplete) {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.placesAutocompleteInputTarget,
          this.autocompleteOptions()
        );
        var autoCompleteCallback = this.setSearchParams.bind(this);
        this.autocomplete.addListener("place_changed", autoCompleteCallback);
        this.dontSubmit(this.placesAutocompleteInputTarget);
      }
    }
  }

  setSearchParams () {
    var place = this.autocomplete.getPlace();
    this.setSearchParamsFromPlace(place);
  }

  setSearchParamsFromPlace(place) {
    var params = this.getSearchParamsFromPlace(place);
    this.updateFormFields(params.geoPosition, params.postalCode);
  }

  setSearchParamsFromIncompleteInput(event) {
    var inputValue = this.placesAutocompleteInputTarget.value;
    if (inputValue) {
      this.getFirstAutocompleteResult(
        inputValue,
        this.placesAutocompleteInputTarget,
        this.updateFormFieldsFromPlacePrediction.bind(this)
      );
    } else {
      this.resetFormFields();
    }
  }

  updateFormFields (geoPosition, postalCode) {
    var position = geoPosition;

    if (position) {
      this.latitudeInputTarget.value = position[0];
      this.longitudeInputTarget.value = position[1];
      if (this.hasPlayingInInputTarget) { this.playingInInputTarget.value = postalCode || ""; }
    }
  }

  updateFormFieldsFromPlacePrediction (autocompletePrediction) {
    this.getPlaceFromPrediction(
      autocompletePrediction,
      this.setSearchParamsFromPlace.bind(this)
    );
  }

  autocompleteOptions() {
    // TODO: Get these using Stimulus values
    var countryCode = this.element.dataset.countryCode;
    var language = this.element.dataset.language;
    var resultTypes = this.element.dataset.types;
    var types = [];

    if (resultTypes != "") {
      types = [resultTypes];
    }

    var options = {
      componentRestrictions: {
        country: countryCode
      },
      fields: [
        "address_components",
        "geometry"
      ],
      types: types
    }

    return options
  }

  loadAutoComplete () {
    if (!window.googleMapsIsLoaded) {
      const element = this.element;
      const { googlekey, region } = element.dataset;

      if (!document.getElementById('google-maps-sdk')) {
        var script = document.createElement('script');

        const urlOptions = `key=${googlekey}&libraries=places&callback=window.filterGoogleCallback&region=${region}`

        script.id = 'google-maps-sdk';
        script.setAttribute('async', 'true');
        script.setAttribute('defer', 'true');
        script.src = `//maps.googleapis.com/maps/api/js?${urlOptions}`;

        document.body.append(script);
      }
    }
  }
}
